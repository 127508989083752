// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

$(document).on('turbolinks:load', function () {
	console.log("Application loaded!")
	// display filename in custom file input control on selection
	$('.custom-file-input input[type="file"]').change(function (e) {
		$(this).siblings('input[type="text"]').val(e.target.files[0].name)
		console.log("select file:", e.target.files[0].name)
	})
})

import "./custom"
import "bootstrap"
import "../css/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import $ from 'jquery'
global.$ = jQuery
window.$ = jQuery

let dummy = 'dummy'
